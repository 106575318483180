import Home from './routes/home';
import './styles/index.scss';

const App = () => {
  return (
    <div>
      <Home />
    </div>
  );
};

export default App;
